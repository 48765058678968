import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'selectSearch',
  standalone: true,
})
export class SelectSearchPipe implements PipeTransform {
  transform(value: string, search: string): string {
    if (typeof value !== 'string' || !value) {
      return value
    }
    return value.match(new RegExp(`^${search}`, 'gi'))
      ? `<span class="text-primary">${value.substring(0, search.length)}</span>${value.substring(search.length)}`
      : value
  }
}
