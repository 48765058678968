import { Pipe, PipeTransform } from '@angular/core'

import { forecastNumber } from '~/utils/index'

@Pipe({
  name: 'forecastNumber',
  standalone: true,
})
export class ForecastNumber implements PipeTransform {
  transform(value: number | undefined, toFixed = -1, isCurrency = false, ..._args: unknown[]): string {
    return forecastNumber(value, toFixed, isCurrency)
  }
}
