import { Pipe, PipeTransform } from '@angular/core'

import { IUserDto } from '~/core/models'

@Pipe({
  name: 'userName',
  standalone: true,
})
export class UserNamePipe implements PipeTransform {
  transform(value?: IUserDto | null): string {
    if (!value) {
      return ''
    }
    return value.firstName || value.lastName ? `${value.firstName} ${value.lastName}` : value.email.split('@')[0]
  }
}
