import { Pipe, PipeTransform } from '@angular/core'

import { forecastPercentNumber } from '~/utils/index'

@Pipe({
  name: 'forecastPercentNumber',
  standalone: true,
})
export class ForecastPercentNumber implements PipeTransform {
  transform(value: number | undefined, toFixed = -1, isCurrency = false, ..._args: unknown[]): string {
    return forecastPercentNumber(value, toFixed, isCurrency)
  }
}
