import { Pipe, PipeTransform } from '@angular/core'

import { getForecastCompanySize } from '~/utils/index'

@Pipe({
  name: 'forecastCompanySize',
  standalone: true,
})
export class ForecastCompanySize implements PipeTransform {
  transform(companySize: string): string {
    return getForecastCompanySize(companySize)
  }
}
